import React, { useMemo, useState } from "react";
import { useStripe, useElements, CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import Button from "../../../components/Buttons/Button";
import {useAppDispatch} from "../../../redux/store/store";
import useAddPaymentMethod from "../../../react-query-hooks/useAddPaymentMethod";
import {getUserData} from "../../../helpers/helperMethods";
import { useAuthState } from "../../../context/UserAuthentication";
import useProfile from '../../../react-query-hooks/useProfile';
import InputCheckbox from '../../../components/FormFields/Checkbox/InputCheckbox';
import BasicSpinner from "../../../components/Spinner/BasicSpinner";
import {useQueryClient} from "react-query";
import { zipCodeSchema } from "../../../validationSchemas/paymentRecordSchema";
import {GUEST_USER} from "../../../constants";
import {Toast_Func} from "../../../helpers/toast.helper";
import { bugSnagNotifyAPIError } from "../../../helpers/bugSnagHelper";

interface ICheckoutForm {
    handleCheckoutNewPaymentSuccess?:  (data, is_saved) => void
    handleCheckoutNewPaymentFailure?: () => void
    isCheckout: boolean
    closeProfileModal?: () => void
}

const CheckoutForm = (props: ICheckoutForm) => {
  const {
      handleCheckoutNewPaymentSuccess,
      handleCheckoutNewPaymentFailure,
      isCheckout, closeProfileModal
  } = props
  const stripe = useStripe();
  const elements = useElements();

  const { authInfo } = useAuthState();

  let { mutateAsync: addPayment } = useAddPaymentMethod()
  const { data: userProfileData } = useProfile(authInfo.userId, authInfo.type)
  const userInfo = getUserData(authInfo, userProfileData)
  let queryClient = useQueryClient();

  const [paymentMethodName, setPaymentMethodName] = useState<string>('')
  const [paymentMethodNameError, setPaymentMethodNameError] = useState<string>(null)
  const [zipCode, setZipCode] = useState<string>('')
  const [zipCodeError, setZipCodeError] = useState<string>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [stripeError, setStripeError] = useState<any>(null)
  const [isDefault, setIsDefault] = useState(false);
  const [isSaveCard, setIsSaveCard] = useState(!isCheckout);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStripeError(null)
    setPaymentMethodNameError(null)
    setZipCodeError(null)    
    if(isSaveCard && paymentMethodName.trim() == ''){ 
        setPaymentMethodNameError("Payment Method required.")
        return
    }
    setLoading(true)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
            name: paymentMethodName,
        },
      });

    if(error) {
        setStripeError(error)
        setLoading(false)
        return
    }
    try { 
        zipCodeSchema.validateSync(zipCode)
    }
    catch (error) { 
        setZipCodeError(error.message) 
        setLoading(false)
        return 
    }

    // console.log(userInfo, paymentMethod, "PAYMENT")

    const data = { 
        customer : {
            name : userInfo?.full_name,
            email: userInfo?.email,
            id: userInfo.id
        },
        card : {
            id: paymentMethod?.id,
            card_ending_num : paymentMethod?.card?.last4,
            exp_year : paymentMethod?.card?.exp_year,
            exp_month : paymentMethod?.card?.exp_month
        },
        name: paymentMethodName,
        postal_code : zipCode,
        is_saved : isSaveCard,
        is_default: isDefault
        
    }

    await addPayment({ newPaymentMethod: data },
       {
         onSuccess: (data) => {
           if(isCheckout)
           {
               handleCheckoutNewPaymentSuccess(data, isSaveCard)
           }
           else {
               queryClient.invalidateQueries("paymentMethod");
               closeProfileModal()
           }
           setLoading(false)
         },
         onError: (error: any) => {
           Toast_Func({ status: false, message: 'Something went wrong' });
           bugSnagNotifyAPIError("Add Payments", data, error?.response?.data.message);
           setLoading(false)
         }})

  };
  const options ={
    style: {
      base: {
        fontSize: "0.8333333",
        color: "#000",
        letterSpacing: "0.025em",
        lineHeight: "30px",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };


  return (
    <form onSubmit={handleSubmit} className="new_form_design my-0 mx-auto delivery_location_select full_width_mob" >
        {
            authInfo.type != GUEST_USER ?
                <div className="form-group">
                    <label className="d-flex justify-content-between align-items-center">
                        Name Payment Method
                    </label>
                    <input
                        name="name"
                        type="text"
                        className="form-control no-style"
                        onChange={(e)=>{setPaymentMethodName(e.target.value)}}
                        value={paymentMethodName}
                        data-testid="payment-cardName"
                    />
                    {paymentMethodNameError ? <span className={"clr-dark-red f-s11"}>{paymentMethodNameError}</span> : null }
                </div> :  null
        }

        <div className="">
            <label>
                Card number
            </label>
            <CardNumberElement
                options={options}
            />
            {stripeError && stripeError?.code.includes('number') ? <span className={"clr-dark-red f-s11"}>{stripeError?.message}</span> : null }
        </div>
        
        <div className="credentials row py-3">

            <div className="col-md-6 col-sm-12 pb-3 pb-md-0">
                <label>
                    CVC
                </label>
                <CardCvcElement
                   options={options}
                />
                {stripeError && stripeError?.code.includes('cvc') ? <span className={"clr-dark-red f-s11"}>{stripeError?.message}</span> : null }            
            </div>

            <div className="col-md-6 col-sm-12">
                <label>
                    Expiration date
                </label>
                <CardExpiryElement
                   options={options}
                />
                {stripeError && stripeError?.code.includes('expiry') ? <span className={"clr-dark-red f-s11"}>{stripeError?.message}</span> : null }            
            </div>
        
        </div>


        <div className="form-group">
            <label className="d-flex justify-content-between align-items-center">
                ZIP CODE
            </label>
            <input
                type="text"
                className="form-control no-style"
                placeholder="Enter Zip Code"
                onChange={(e)=>{setZipCode(e.target.value)}}
                value={zipCode}
                data-testid="checkout-payment-card-zip"
            />
            {zipCodeError ? <span className={"clr-dark-red f-s11"}>{zipCodeError}</span> : null}
        </div>


        {
            isCheckout && authInfo.type != GUEST_USER ?
            <>
                <InputCheckbox
                    id="save_card"
                    name="save_card"
                    onChange={(e) => {
                        setIsSaveCard(!isSaveCard)

                    }}
                    checked={isSaveCard}
                    labelText="Save card information to my account"
                    dataTestid={"Save-payment-card"}
                />

                <InputCheckbox
                    id="default_payment"
                    name="is_default"
                    onChange={(e) => {
                        setIsDefault(!isDefault)
                    }}
                    checked={isDefault}
                    labelText="Make this my default payment method"
                    dataTestid={"make-default-payment-card"}
                />
            </> : null
        }
   
        <div className="d-flex justify-content-center my-5">
            <Button type="submit" className="btn btn-large py-2 f-s16"  disabled={!stripe || loading}
            id={'add-payment-method-button'}>
               {/* { loading ? "Add Payment Method" : <BasicSpinner/>} */}
               Add Payment Method
            </Button>
        </div>
  </form>
  );
};
export default CheckoutForm;