import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import * as CONSTANT from "../../constants";
import { useAuthState } from "../../context/UserAuthentication";
import { Toast_Func } from "../../helpers/toast.helper";
import { API_ERROR } from "../../models/error.model";
import { VehicleInfoFormFields } from "../../models/forms.model";
import useAddVehicleInfo from "../../react-query-hooks/useAddVehicleInfo";
import useProfile from "../../react-query-hooks/useProfile";
import useUpdateVehicleDetails from "../../react-query-hooks/useUpdateVehicleDetails";
import { FormField } from "../FormFields/FormField";
import { vehicleInfoSchema } from "../../validationSchemas/vehicleInfoSchema";
import { customerBusinessService } from "../../services";

interface VehicleInfo {
  show: boolean;
  title: string;
  id: number | null;
  forOneTimeOnly?: boolean;
}

interface IVehicleInfoForm {
  closeModal: () => void;
  vehicleId?: string | number;
  addVehicleInfoForOneTime?: (data) => void;
  editVehicleInfoForOneTime?: (data) => void;
  vehicleInfoForOneTime?: VehicleInfoFormFields[];
  vehicleInfo?: VehicleInfo;
}

const VehicleInfoForm: React.FC<IVehicleInfoForm> = ({
  vehicleId,
  closeModal,
  addVehicleInfoForOneTime,
  vehicleInfoForOneTime,
  editVehicleInfoForOneTime,
  vehicleInfo,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [colors, setColors] = useState([]);

  let queryClient = useQueryClient();
  let { authInfo } = useAuthState();
  let { data } = useProfile(authInfo.userId);
  let { mutateAsync: addVehicleInfo } = useAddVehicleInfo();
  let { mutateAsync: updateVehicleDetails } = useUpdateVehicleDetails();

  let makes = vehicleOptions.map((element: any) => ({
    value: element.make,
    label: element.make,
  }));
  let color = colors.map((element) => ({ value: element, label: element }));

  const getVehicleInfo = () => {
    if (vehicleInfo.forOneTimeOnly)
      return vehicleInfoForOneTime.find((item) => item.id == vehicleInfo.id);
    else if (vehicleId)
      return data.vehicles.find((vehicle: any) => vehicle.id === vehicleId);
    else return "";
  };

  let vehicleData = getVehicleInfo();

  const modelsValue = (values) => {
    return vehicleOptions
      .find((element: any) => element.make === values.make)
      ?.model.map((element) => ({ value: element, label: element }));
  };

  const initialFormState: VehicleInfoFormFields = vehicleData
    ? {
        make: vehicleData.make || "",
        model: vehicleData.model || "",
        color: vehicleData.color || "",
        is_default: vehicleData.is_default || 0,
      }
    : {
        make: "",
        model: "",
        color: "",
        is_default: 0,
      };

  const valueModifier = (value: string) => {
    return { value: value, label: value };
  };

  const handleEditVehicleInfoForOneTime = (modifiedValues) => {
    editVehicleInfoForOneTime({
      ...modifiedValues,
      id: vehicleData.id,
    });
    closeModal();
    Toast_Func({
      status: true,
      message: "Vehicle information successfully added",
    });
  };

  const handleAddVehicleInfoForOneTime = (modifiedValues) => {
    addVehicleInfoForOneTime({
      ...modifiedValues,
      id: vehicleInfoForOneTime.length,
    });
    closeModal();
    Toast_Func({
      status: true,
      message: "Vehicle information successfully added",
    });
  };

  const handleFormSubmission = async (
    values: VehicleInfoFormFields,
    { setSubmitting }: any
  ) => {
    let modifiedValues = {
      ...values,
      customer_id: authInfo.userId,
      is_default: isDefault ? 1 : 0,
    };

    if (vehicleInfo.forOneTimeOnly) {
      handleEditVehicleInfoForOneTime(modifiedValues);
      setSubmitting(false);
      return;
    }

    let mutate = vehicleId ? updateVehicleDetails : addVehicleInfo;
    return mutate(
      {
        vehicleInfo: modifiedValues,
        ...(vehicleId && { vehicleId }),
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries("profile");
          closeModal();
          setSubmitting(false);
          Toast_Func({
            status: true,
            message: "Vehicle information successfully added",
          });
        },
        onError: (error, variables, context) => {
          const {
            data: {
              data: { errors },
            },
          } = (error as API_ERROR).response;
          closeModal();
          setSubmitting(false);
          Toast_Func({ status: false, message: errors[0] });
        },
      }
    );
  };

  useEffect(() => {
    let isMounted = true;     
    setLoading(true);
    customerBusinessService
      .getVehicles()
      .then((response) => {
        setLoading(false);
        if (isMounted){
          setVehicleOptions(response.data.data.vehicles);
          setColors(response.data.data.colors)
          setIsDefault(initialFormState.is_default);
        }
      })
      .catch((error) => {
        Toast_Func({
          status: false,
          message: error.response.data.message,
        });
        setLoading(false);
      });
      return () => { isMounted = false };
  }, []);

  const CheckBox = ({ onClick, text, checked }) => {
    return (
      <div className="form-group theme_check_boxes" onClick={onClick}>
        <input
          type="checkbox"
          id="checkbox_vehicle_info"
          name="radio-group"
          checked={checked}
        />
        <label
          htmlFor="checkbox_vehicle_info"
          className="d-flex align-items-center f-s14"
        >
          <span className="box_wrap"></span> {text}
        </label>
      </div>
    );
  };

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={vehicleInfoSchema}
      onSubmit={handleFormSubmission}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => (
        <form className="new_form_design" onSubmit={handleSubmit}>
          <FormField
            value={values.make ? valueModifier(values.make) : null}
            options={makes}
            name={"make"}
            type={"select"}
            labelText={"MAKE"}
            placeholder={"Make a selection"}
            errors={errors}
            touched={touched}
            onChange={(make) => {
              setFieldValue("make", make.value);
              setFieldValue("model", "");
            }}
            inputFieldClass={"custom_select mb-0"}
            isSearchable={true}
            dataTestId={'vehicle-select-div'}
          />
          <FormField
            value={values.model ? valueModifier(values.model) : null}
            options={modelsValue(values)}
            name={"model"}
            type={"select"}
            labelText={"Model"}
            placeholder={"Make a selection"}
            errors={errors}
            touched={touched}
            onChange={(model) => setFieldValue("model", model.value)}
            inputFieldClass={"custom_select mb-0"}
            isSearchable={true}
            dataTestId={'vehicle-select-div'}
          />
          <FormField
            value={values.color ? valueModifier(values.color) : null}
            name={"color"}
            options={color}
            type={"select"}
            labelText={"COLOR"}
            placeholder={"Make a selection"}
            errors={errors}
            touched={touched}
            onChange={(color) => setFieldValue("color", color.value)}
            inputFieldClass={"custom_select mb-0"}
            isSearchable={true}
            dataTestId={'vehicle-select-div'}
          />
          <div className="d-flex justify-content-center mt-5">
            <button
              type="submit"
              className="btn btn-large py-2 my-3 f-s16  text-uppercase"
              disabled={isSubmitting}
              onSubmit={submitForm}
            >
              {!vehicleId ? "save vehicle details" : "CONFIRM CHANGES"}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default VehicleInfoForm;
