import React from "react";

import styleClasses from "../../Header/Header.module.scss";
import { quantityCounterOperations as operations } from "../../../models/cart.model";

interface IQuantityCounter {
  quantity?: number;
  editQuantity?: (operation: operations) => void;
  disabled?: boolean;
  itemDetailCounterStyle?: string;
}

const QuantityCounter: React.FC<IQuantityCounter> = (props) => {
  const { quantity, editQuantity, disabled = false ,itemDetailCounterStyle} = props;

  const increaseQuantity = () => editQuantity(operations.increase);
  const decreaseQuantity = () => editQuantity(operations.decrease);

  return (
    <div className="text-center me-3">
      <span className="clr-text-grey f-s14 ls-normal lh-normal font-Visby-cf">Quantity</span>
      <span
        className={`${styleClasses.card_increments} d-flex align-items-center ${itemDetailCounterStyle}`}
      >
        <button
          type="button"
          className="btn btn-custom f-s18 f-w4 font-rale py-1 lh-normal"
          onClick={decreaseQuantity}
          disabled={quantity === 1 || disabled}
        >
          -
        </button>
        <span className="d-inline-block px-4 f-s16" data-testid="quantity-counter">{quantity}</span>
        <button
          type="button"
          className="btn btn-custom f-s18 f-w4 font-rale py-1 lh-normal"
          onClick={increaseQuantity}
          disabled={disabled}
        >
          +
        </button>
      </span>
    </div>
  );
};

export default QuantityCounter;
