import React from 'react'

interface FormFieldProps {
    id?: string
    name:string
    onChange: ((e: React.ChangeEvent<any>) => void) & ((values: any) => void);
    checked: boolean
    labelText?: string;
    dataTestid?: string;
  }


const InputCheckbox: React.FC<FormFieldProps> = ({id, name, onChange,checked, labelText, dataTestid}) => {

    return (
        <>
            <div className="checkbox-wrapper form-group pt-2 input-checkbox">
                <label className='d-flex align-items-center f-s18'>
                    <input type="checkbox"
                        id={id || ""}
                        name={name || ""}
                        onChange={onChange}
                        checked={checked}
                        data-testid={dataTestid}
                         />
                    <span className='f-s18 ms-2 font-rale'> {labelText || ''} </span>
                </label>
            </div>
        </>
    )
}

export default InputCheckbox

