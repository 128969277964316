import React, { useState } from "react";
import { cartItem, IOfferItem } from "../../../models/cart.model";
import styleClasses from "../../Header/Header.module.scss";
import styles from './CartItems.module.scss' 
import { verifyStatus } from "../../../helpers/customizedItem/customizedItem.helper";
import CartItem from "./CartItem";
import { isIndexFound } from "../../../helpers/cartHelperMethods";
import Spinner from "../../Spinner/Spinner";

interface ICartItemsList {
  selectedOfferItem: IOfferItem;
  rewardsDiscountedSubTotal: number;
  handleItemRemove: (
    item_id: string | number,
    iteratingIndex: number,
    closeModal: Function
  ) => void;
  handleQuantityChange: (
    item_id: string | number,
    iteratingIndex: number,
    itemQuantity: number
  ) => void;
  cart: any[];
  allRewards: any;
  loading: boolean;
  scrollcart: any;
  setMyRewards: (any) => void,
  syncingCart: boolean;
  isAutoRedeeming: boolean;
}

const CartItemsList = ({
  syncingCart,
  isAutoRedeeming,
  selectedOfferItem,
  rewardsDiscountedSubTotal,
  allRewards,
  handleItemRemove,
  handleQuantityChange,
  loading,
  cart,
  scrollcart,
  setMyRewards
}: ICartItemsList) => {
  const [isRedeeming, setIsRedeeming] = useState(false);

  const getMatchedMyRewardItem = (item) => {
    if (item?.reward_redeemed) {
      return allRewards.find(reward => reward.loyalty_id == item.reward_id)
    }
    else {
      const foundRewardIndex = allRewards.findIndex(reward => isIndexFound(item, reward) && !reward?.is_active_in_cart)
      return foundRewardIndex >= 0 ? allRewards[foundRewardIndex] : null
    }
  };

  const redeemableItemFromRewardCatalog = (item: cartItem) => {
    if (item?.redeem_points || item?.redemption_by_points) {
      return item
    }
    else return null;
  };

  if (syncingCart)
    return (
      <div className="py-5 d-flex justify-content-center">
        <Spinner className={styles.cart_spinner} showFooter={"d-none"} />
      </div>
    );

  return (
    <>
      <ul
        className={` ${styleClasses.items_list + " list-inline"} ${scrollcart}`}
        data-testid={'cart-items'}
      >
        {cart.length > 0 ? (
          cart.map((item: cartItem, idx: number) => (
            <CartItem
              key={item.id}
              selectedOfferItem={selectedOfferItem}
              rewardsDiscountedSubTotal={rewardsDiscountedSubTotal}
              isAutoRedeeming={isAutoRedeeming}
              isRedeeming={isRedeeming}
              setIsRedeeming={setIsRedeeming}
              item={item}
              iteratingIndex={idx}
              myReward={getMatchedMyRewardItem(item)}
              redeemable={redeemableItemFromRewardCatalog(item)}
              handleItemRemove={handleItemRemove}
              handleQuantityChange={handleQuantityChange}
              isActive={verifyStatus(item, "item_location")}
              loading={loading}
              setMyRewards={setMyRewards}
              myRewards={allRewards}
            />
          ))
        ) : (
          <h1 className="ls-normal lh-normal font-Cls f-sm-s18 f-s28 text-capitalize text-center">
            Your cart is empty
          </h1>
        )}
      </ul>
    </>
  );
};

export default CartItemsList;